import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

export const UserFullDataCard = (props) => {
  const [priceHistoryState, setPriceHistoryState] = useState(false);
  const [salesHistoryState, setSalesHistoryState] = useState(false);
  const [salesHistory, setSalesHistory] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const date = new Date(props?.createdAt).toLocaleDateString();

  // URL
  const DELETEUSER_URL = "api/users/deleteUser/" + props._id;
  const GETSALESBYID_URL = "api/sales/getSalesByUserId/" + props._id;

  useEffect(() => {
    const getSales = async () => {
      const fetchSales = await axiosPrivate.get(GETSALESBYID_URL);
      setSalesHistory(fetchSales.data);
    };
    getSales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCoso = () => {
    props.getDeleteState(true);
  };

  const handleDelete = async () => {
    try {
      await axiosPrivate.delete(DELETEUSER_URL);
      handleCoso();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="saleslist_card">
      <div>
        <h5>
          <div className="saleslist_card_header_approved">
            <p id="text-bold">
              {props?.firstName} {props?.lastName}
            </p>
          </div>
        </h5>

        <div className="saleslist_card_body">
          <div className="saleslist_card_body_left">
            <div>
              <p id="text-bold">Pais:</p>
              <p className="padding_right_10px" id="padding_left_3px">
                {props?.country}
              </p>
              <br />
              <p id="text-bold">Ciudad:</p>
              <p id="padding_left_3px">{props?.city}</p>
              <br />
              <p id="text-bold">Dirección:</p>
              <p id="padding_left_3px">
                {props?.address}, <span id="text-bold">CP:</span>{" "}
                {props?.postalCode}
              </p>
              <br />
              <p id="text-bold">Teléfono:</p>
              <p id="padding_left_3px">{props?.phoneNum}</p>
              <br />
              <br />
              <p id="text-bold">Email:</p>
              <p id="padding_left_3px">{props?.email}</p>
            </div>
            <br />
            <p id="text-bold">Puntaje actual:</p>
            <p id="padding_left_3px">{props?.pointsEarned}</p>
            <br />

            {priceHistoryState && (
              <>
                <br />
                <p id="text-bold">Noches Elite:</p>
                <p id="padding_left_3px">{props?.eliteNights}</p>
                <br />
                <p id="text-bold">Premios:</p>
                {props?.AB_Prize.map((item, index) => {
                  return (
                    <div key={index}>
                      <p>{item}</p>
                      <br />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="saleslist_card_body_right">
            <p id="text-bold" className="padding_left_3px">
              Fecha registro:
            </p>
            <br />
            <p id="padding_left_3px">{date}</p>
            <br />
            <br />
            <p id="text-bold" className="padding_left_3px">
              Llego por:
            </p>
            <br />
            <p id="padding_left_3px">{props?.reachBy}</p>
          </div>
        </div>
        {salesHistoryState && (
          <div className="saleslist_card_reservations">
            <p id="text-bold">Reservas:</p>
            {salesHistory.length > 0 ? (
              <>
                {salesHistory.map((item) => {
                  return (
                    <>
                      <div className="salelist_history_container">
                        <div className="salelist_history">
                          <p id="text-bold">{item.tipoAgente}</p>
                          <p id="text-bold">-</p>
                          <p>{item.procesadaPor}</p>
                        </div>
                        <div className="salelist_history">
                          <p id="text-bold">{item.hotelSelector}</p>
                          <p id="text-bold">-</p>
                          <p>
                            {item.catHabitacion ===
                            "El agente con más ventas (que cumpla con más de 10 reservas al destino) o con ventas de un valor de 25.000 uds o más."
                              ? "+10 reservas al destino o ventas de valor + 25.000 usd"
                              : item.catHabitacion}
                          </p>
                          <p id="text-bold">-</p>
                          <p>
                            <span id="text-bold">Cant. Noches:</span>{" "}
                            {item.nightsQuantity}
                          </p>
                        </div>
                        <div className="salelist_history">
                          <p>
                            <span id="text-bold">Check-in:</span>{" "}
                            {item.fechaCheckIn}
                          </p>
                          <p id="text-bold">-</p>
                          <p>
                            <span id="text-bold">Check-out:</span>{" "}
                            {item.fechaCheckOut}
                          </p>
                        </div>
                        <div className="salelist_history">
                          <p>
                            <span id="text-bold">Validación AB:</span>{" "}
                            {item.validacionAB}
                          </p>
                          <p id="text-bold">-</p>
                          <p>
                            <span id="text-bold">Validación Elite:</span>{" "}
                            {item.validacionElite}
                          </p>
                        </div>
                      </div>
                      <hr className="divisor" />
                    </>
                  );
                })}
              </>
            ) : (
              <p>No hay reservas cargadas</p>
            )}
          </div>
        )}
        <div className="admin_card_body_correctionText">
          <div className="admin_card_buttons_left">
            <button
              id="request-button"
              onClick={() => setSalesHistoryState(!salesHistoryState)}
            >
              Historial de reservas
            </button>
            <button
              id="request-button"
              onClick={() => setPriceHistoryState(!priceHistoryState)}
            >
              Historial de premios
            </button>
          </div>
          <button id="delete-button" onClick={handleDelete}>
            Eliminar Usuario
          </button>
        </div>
      </div>
    </div>
  );
};
