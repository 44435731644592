import { useState, useEffect } from "react";
import Navbar from "../../nav/NavbarAdmin";
import Footer from "../../layout/Footer";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { UserFullDataCard } from "./Cards/UserFullDataCard";
// imagenes
import abGuruLogo from "../../../assets/images/abguru_logo.png";

// bootstrap
import Container from "react-bootstrap/Container";

const GestionUsuarios = () => {
  const [usersList, setUsersList] = useState([]);
  const [deleteState, setDeleteState] = useState(false);


  const axiosPrivate = useAxiosPrivate();

  // URL
  const GETALLUSERS_URL = "api/users/getUsers";

  useEffect(() => {
    const getUsersList = async () => {
      try {
        const fetchUsersList = await axiosPrivate.get(GETALLUSERS_URL);
        setUsersList(fetchUsersList.data.reverse());
      } catch (err) {
        console.log(err);
      }
    };
    getUsersList();
    //eslint-disable-next-line
  }, [deleteState]);

  const userFullDataModel = usersList.map((userList) => {
    return (
      <div key={usersList._id}>
        <UserFullDataCard getDeleteState={setDeleteState} {...userList} />
        <br />
      </div>
    );
  });

  return (
    <div className="main_container">
      <div>
        <img src={abGuruLogo} alt="" className="logo_header" />
      </div>
      <section>
        <Navbar />
        <div className="section_container">
          <h2>Gestión de usuarios</h2>
          <Container>
            {usersList.length === 0 ? (
              <div>
                <h3 className="hotelSelector_default">
                  No hay usuarios cargados por el momento.
                </h3>
              </div>
            ) : (
              <div>{userFullDataModel}</div>
            )}
          </Container>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default GestionUsuarios;
