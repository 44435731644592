import { useState, useEffect } from "react";
import Navbar from "../nav/NavbarAdmin";
import Footer from "../layout/Footer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

// imagenes
import abGuruLogo from "../../assets/images/abguru_logo.png";

// bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { UserCard } from "./Cards/UserCard";

const GETCURRENTUSER_URL = "api/users/currentUser";
const GETALLUSERS_URL = "api/users/getUsers";

const Admin = () => {
  const [admin, setAdmin] = useState("");
  const [adminDataState, setAdminDataState] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const fetchUser = await axiosPrivate.get(GETCURRENTUSER_URL);
        setAdmin(fetchUser.data);
        setAdminDataState(true);
      } catch (err) {
        console.log(err);
      }
    };
    getUserData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getUsersList = async () => {
      try {
        const fetchUsersList = await axiosPrivate.get(GETALLUSERS_URL);
        setUsersList(fetchUsersList.data.reverse());
      } catch (err) {
        console.log(err);
      }
    };
    getUsersList();
    //eslint-disable-next-line
  }, [adminDataState]);

  const RegisteredUsers = usersList.map((usersList, index) => {
    return (
      <div key={index}>
        <UserCard {...usersList} />
        <br />
      </div>
    );
  });

  // URL
  return (
    <div className="main_container">
      <div>
        <img src={abGuruLogo} alt="" className="logo_header" />
      </div>
      <section>
        <Navbar />
        <div className="section_container">
          <h2 id="color-pink">
            Bienvenido {admin.firstName} {admin.lastName}
          </h2>
          <div>
            <Row>
              <Col>
                <h5 className="admin_home_pending_bar">
                  <span id="text-bold">Usuarios Registrados</span> (Cantidad: {usersList.length})
                </h5>
              </Col>
            </Row>
            <Row className="admin_titles_bar">
              <Col xs={4}>
                <h5 id="text-bold">Nombre</h5>
              </Col>
              <Col xs={6}>
              <h5 id="text-bold">Email</h5>
              </Col>
              <Col xs={2}>
              <h5 id="text-bold">Registro</h5>
              </Col>
            </Row>
            {usersList.length === 0 ? (
              <h5 id="padding_6px">No hay usuarios registrados actualmente.</h5>
            ) : (
              <div className="section_loadedsales_container" id="padding_6px">
                {RegisteredUsers}
              </div>
            )}
          </div>
        </div>
        <br />
      </section>
      <Footer />
    </div>
  );
};

export default Admin;
