// bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const UserCard = (props) => {
  const date = new Date(props?.createdAt).toLocaleDateString();

  return (
    <div>
      <Row className="mb-n1">
        <Col xs={4}>
          <p id="text-bold">
            {props?.firstName} {props?.lastName}
          </p>
        </Col>
        <Col xs={6}>
          <p id="text-bold">{props?.email}</p>
        </Col>
        <Col xs={2}>
          <p id="text-bold">{date}</p>
        </Col>
      </Row>
    </div>
  );
};
